<template>
  <div
    class="explorer"
  >
    <div class="explorer-content-main">
      <PaginationButtons
        :go-to-prev-page="goToPrevPage"
        :go-to-next-page="goToNextPage"
        :page-number="entitiesPageIndex + 1"
        :disable-prev="entitiesPageIndex === 0 || loading"
        :disable-next="isLastPage || loading"
      />
      <loading-overlay
        v-show="entitiesStoreIsLoading"
        :overlay-opaque="true"
        :style="{'background': 'transparent'}"
      />
      <entity-table
        :entities="(entitiesPage?.entities ?? [])"
        :is-loading="entitiesStoreIsLoading"
        :row-selection-data="entitySelection"
        @updateRowSelection="onUpdateEntitySelection"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { allowedStates } from '@/store/helpers/storeState';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import EntityTable from '@/components/entities/EntityTable.vue';
import PaginationButtons from './PaginationButtons.vue';

export default {
  components: {
    EntityTable,
    PaginationButtons,
    LoadingOverlay,
  },
  props: {
    entitySelection: {
      type: Object,
      required: true,
    },
  },
  emits: ['updateEntitySelection'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      entitiesPage: 'entitiesV2/entitiesPage',
      entitiesPageIndex: 'entitiesV2/entitiesPageIndex',
      isLastPage: 'entitiesV2/isCurrentPageLastPage',
      storeStatus: 'entitiesV2/storeStatus',
    }),
    entitiesStoreIsLoading() {
      return this.storeStatus === allowedStates.IS_LOADING || this.storeStatus === allowedStates.IS_BLANK;
    },
    totalEntitiesOnCurrentPage() {
      return this.entitiesPage?.entityCount;
    },
  },
  watch: {
    totalEntitiesOnCurrentPage(newVal) {
      if (newVal === 0 && this.entitiesPageIndex !== 0) {
        this.goToPrevPage();
      }
    },
  },
  methods: {
    ...mapActions({
      nextPage: 'entitiesV2/nextPage',
      prevPage: 'entitiesV2/prevPage',
    }),
    onUpdateEntitySelection(selection) {
      this.$emit('updateEntitySelection', selection);
    },
    async goToNextPage() {
      this.loading = true;
      if (!this.isLastPage) {
        await this.nextPage();
      }
      this.loading = false;
    },
    async goToPrevPage() {
      this.loading = true;
      if (!(this.entitiesPageIndex <= 0)) {
        await this.prevPage();
      }
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.explorer {
  position: relative;
}
</style>
