<template>
  <table class="contained-table">
    <tr :style="{zIndex: '1', position: 'relative'}">
      <th class="table-col-0">
        <checkbox-input
          id="entitySelectAll"
          :model-value="allSelected"
          @update:modelValue="onToggleSelectAll"
        />
      </th>
      <th>
        <span>Name</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('name')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'name' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>AKA</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('akaCount')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'akaCount' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Relationships</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('relationshipCount')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'relationshipCount' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th>
        <span>Type</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('metricIdentifierDisplayName')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'metricIdentifierDisplayName' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>ID</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('interDocumentEntityId')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'interDocumentEntityId' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
      <th class="text-centre">
        <span>Last Edit</span>
        <span
          class="icon-container column-header-icon"
          @click="sortBy('timeUpdated')"
        >
          <triangle-icon
            class="icon-sort"
            :class="selectedOrderByColumn === 'timeUpdated' && sortIconClass"
            size="0.75x"
          />
        </span>
      </th>
    </tr>
    <tr
      v-for="entity in entities"
      :key="entity.interDocumentEntityId"
    >
      <td class="table-col-0">
        <checkbox-input
          :id="`entitySelect-${entity.interDocumentEntityId}`"
          :model-value="rowSelectionData[entity.interDocumentEntityId] ? true : false"
          @update:modelValue="onToggleSelectRow(entity.interDocumentEntityId)"
        />
      </td>
      <td class="name-column">
        <router-link
          :to="{ name: 'entity', params: { name: entity.name }}"
          class="cell-link"
        >
          {{ entity.name }}
        </router-link>
      </td>
      <td class="text-centre aka-count-column">
        {{ entity.akaCount }}
      </td>
      <td class="text-centre relationship-count-column">
        {{ entity.relationshipCount ?? '' }}
      </td>
      <td class="type-column">
        <div
          v-if="entity.displayName.length > 0"
          class="type-pills"
        >
          <pill
            v-for="(entityType, idx) in entity.displayName"
            :key="idx"
            :label="entityType"
          />
        </div>
        <span
          v-else
          class="missing-info-placeholder"
        >
          Not defined
        </span>
      </td>
      <td class="text-centre id-column">
        <span v-if="entity.clientIdentifier">{{ entity.clientIdentifier }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >No ID found</span>
      </td>
      <td class="text-centre last-edit-column">
        <span v-if="entity.timeUpdated">{{ formatDate(entity.timeUpdated) }}</span>
        <span
          v-else
          class="missing-info-placeholder"
        >-</span>
      </td>
    </tr>
  </table>
  <p
    v-if="!entities.length && !isLoading"
    class="hint"
  >
    No entities to display.
  </p>
</template>
<script>
import dayjs from 'dayjs';
import Pill from '@/components/general/Pill.vue';
import { TriangleIcon } from '@zhuowenli/vue-feather-icons';
import { mapGetters, mapActions } from 'vuex';
import CheckboxInput from '../forms/CheckboxInput.vue';

export default {
  components: {
    Pill,
    CheckboxInput,
    TriangleIcon,
  },
  props: {
    entities: {
      type: Array,
      required: true,
    },
    rowSelectionData: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updateRowSelection'],
  data() {
    return {
      selectedOrderByColumn: null,
    };
  },
  computed: {
    ...mapGetters({
      orderBy: 'entitiesV2/orderBy',
    }),
    sortIconClass() {
      const columnName = this.selectedOrderByColumn;
      return {
        'sort-show': this.orderByColumn === columnName,
        'sort-asc': this.orderByDirection === 'asc',
        'sort-desc': this.orderByDirection === 'desc',
      };
    },
    allSelected() {
      const ids = this.entities.map((entity) => entity.interDocumentEntityId);
      const selectedEntities = Object.keys(this.rowSelectionData).filter((entityId) => this.rowSelectionData[entityId] === true);
      return ids.every((val) => selectedEntities.includes(val));
    },
    orderByColumn() {
      return this.orderBy !== null ? this.orderBy.split(':')[0] : null;
    },
    orderByDirection() {
      return this.orderBy !== null ? this.orderBy.split(':')[1] : null;
    },
  },
  methods: {
    ...mapActions({
      sortEntities: 'entitiesV2/sortEntities',
    }),
    sortBy(columnName) {
      this.selectedOrderByColumn = columnName;
      // By default, sort column by ascending
      const initialSortDirection = 'asc';
      const secondarySortDirection = 'desc';
      let order = `${columnName}:${initialSortDirection}`;
      // If column has already been sorted once, sort by descending
      if (this.orderByColumn === columnName && this.orderByDirection === initialSortDirection) {
        order = `${columnName}:${secondarySortDirection}`;
      } else if (this.orderByColumn === columnName && this.orderByDirection === secondarySortDirection) {
        order = null; // Remove sort on the third attempt
      }
      this.sortEntities({ orderBy: order });
    },
    formatDate(datetime) {
      if (dayjs(datetime).isValid()) {
        return dayjs(datetime).format('MMM DD YYYY');
      }
      return '';
    },
    onToggleSelectAll() {
      const allRowsCurrentlySelected = this.allSelected;
      const selection = this.entities.reduce((ac, entity) => ({ ...ac, [entity.interDocumentEntityId]: !allRowsCurrentlySelected }), {});
      this.$emit('updateRowSelection', selection);
    },
    onToggleSelectRow(rowId) {
      this.$emit('updateRowSelection', { [rowId]: !this.rowSelectionData[rowId] });
    },
  },
};
</script>
<style lang="scss" scoped>
.type-pills {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.contained-table {
  font-size: $txt-p-size;
  margin-bottom: 55px;
  min-width: 900px;

  .name-column, .type-column {
    width: 25%;
    min-width: 225px;
  }

  .aka-count-column, .relationship-count-column, .id-column, .last-edit-column {
    width: 12.5%;
  }

  td {
    vertical-align: middle;
  }

  th {
    white-space: nowrap;
    &.table-col-0 {
      z-index: 1;
    }

    .icon-container:hover {
      background-color: transparent;
    }
  }
}
</style>
