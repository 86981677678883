<template>
  <splitpanes>
    <pane
      class="main-content-pane"
      size="70"
      :style="loadingUserSettings && {position: 'relative'}"
    >
      <div v-if="loadingUserSettings">
        <loading-overlay />
      </div>
      <div
        :style="{visibility: loadingUserSettings ? 'hidden' : 'visible'}"
        class="dashboard explorer-content-main"
      >
        <entity-table-actions
          v-if="totalSelected > 0"
          :text="`${totalSelected} ${totalSelected > 1 ? 'entities' : 'entity'} selected`"
          :user-entity-permissions="userEntityPermissions"
          :loading-delete="loadingDelete"
          @on-click-delete="onDeleteEntities"
          @unselectAll="unselectAllEntities"
        />
        <div
          class="main-container"
        >
          <h1>
            Entities
          </h1>
          <entity-actions
            :style="{'margin-left': 'auto'}"
            :hide-menu="true"
            :user-entity-permissions="userEntityPermissions"
            @add-new="setShowCreateEntityForm(true)"
          />
        </div>
        <div
          class="entities-filter-bar"
        >
          <search-bar
            placeholder="Search Entities..."
            @update-search="updateSearch"
          />
        </div>
        <hr>
        <entities-explorer
          :entity-selection="entitySelection"
          @updateEntitySelection="updateEntitySelection"
          @unselectAllRows="unselectAllEntities"
        />
      </div>
    </pane>
    <pane
      v-if="showCreateEntityForm"
      class="pane-details main-content-pane"
      size="30"
      style="minWidth: 450px"
    >
      <aside
        :style="{ width: '100%', zIndex: '1', position: 'relative' }"
      >
        <entity-creation-form
          @close="setShowCreateEntityForm(false)"
        />
      </aside>
    </pane>
  </splitpanes>
</template>
<script>
import { Pane, Splitpanes } from 'splitpanes';
import LoadingOverlay from '@/components/general/LoadingOverlay.vue';
import EntitiesExplorer from '@/components/entities/EntitiesExplorer.vue';
import debounce from 'lodash.debounce';
import { mapActions, mapGetters } from 'vuex';
import { successMessages, errorMessages } from '@/store/helpers/display/toastMessages';
import { useToast } from 'vue-toastification';
import SearchBar from '@/components/general/SearchBar.vue';
import EntityActions from '@/components/entities/EntityActions.vue';
import EntityCreationForm from '@/components/entities/forms/EntityCreationForm.vue';
import EntityTableActions from '@/components/entities/EntityTableActions.vue';
import 'splitpanes/dist/splitpanes.css';
import { allowedStates } from '@/store/helpers/storeState';

export default {
  components: {
    EntitiesExplorer,
    SearchBar,
    EntityActions,
    EntityCreationForm,
    Pane,
    Splitpanes,
    EntityTableActions,
    LoadingOverlay,
  },
  data() {
    return {
      toast: useToast(),
      searchString: '',
      entitySelection: {},
      showCreateEntityForm: false,
      loadingDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      userEntityPermissions: 'localisation/userEntityPermissions',
      userSettingsStoreStatus: 'localisation/storeStatus',
    }),
    totalSelected() {
      return Object.keys(this.entitySelection).filter((id) => this.entitySelection[id] === true).length;
    },
    loadingUserSettings() {
      return this.userSettingsStoreStatus === allowedStates.IS_LOADING || this.userSettingsStoreStatus === allowedStates.IS_BLANK;
    },
  },
  async mounted() {
    this.$log.info('Entity dashboard loading...');
    try {
      await this.initialiseUserSettings();
    } catch (e) {
      this.toast.error('Error fetching user settings, initialising with defaults');
      this.initSettingsWithDefault();
    }
    this.loadEntitiesFirstPage();
  },
  methods: {
    ...mapActions({
      init: 'entitiesV2/init',
      deleteEntities: 'entitiesV2/deleteEntities',
      filterEntities: 'entitiesV2/filterEntities',
      initialiseUserSettings: 'localisation/lazyInit',
      initSettingsWithDefault: 'localisation/initWithDefault',
    }),
    loadEntitiesFirstPage() {
      this.init()
        .then(() => {
          this.$log.info('Successfully initialised first page of entities');
        })
        .catch((e) => {
          this.$log.error('Error initialising entities', e);
          this.toast.error('Error loading entities');
        });
    },
    updateSearch: debounce(function (query) { // eslint-disable-line func-names
      this.$log.debug('Debounce search triggered:', query);
      this.searchString = query;
      this.filterEntities({ nameContains: this.searchString });
    }, 700),
    async onDeleteEntities() {
      const entityIds = Object.keys(this.entitySelection).filter((entityId) => this.entitySelection[entityId] === true);
      this.$log.info('Deleting entities', entityIds);
      this.loadingDelete = true;
      this.deleteEntities(entityIds)
        .then(() => {
          this.$log.info('Entities deleted:', entityIds);
          this.toast.success(successMessages.DELETED_ENTITIES);
          this.entitySelection = {};
        })
        .catch((e) => {
          this.$log.error('Failed to delete entities:', e);
          this.toast.error(errorMessages.FAILED_DELETE_ENTITY);
        })
        .finally(() => {
          this.loadingDelete = false;
        });
    },
    setShowCreateEntityForm(bool) {
      this.showCreateEntityForm = bool;
    },
    /**
     * Updates entitySelection object
     *
     * @param selection Object of key value pairs, where key is
     * entity interdocument ID and value is boolean representing
     * whether entity has been selected or not.
     * An existing entity which does not have a corresponding key in this object
     * is considered not selected.
     */
    updateEntitySelection(selection) {
      this.entitySelection = {
        ...this.entitySelection,
        ...selection,
      };
    },
    unselectAllEntities() {
      this.entitySelection = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.entities-filter-bar {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding: 0 32px;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
